<template>
  <div>
    <CRow>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader>
            <strong>{{ course.title }}</strong>
          </CCardHeader>
          <CCardBody>
            <ul>
              <li v-for="lesson in lessons" :key="lesson.id">
                {{ lesson.title }}
              </li>
            </ul>
          </CCardBody>
          <CCardFooter>
              <CButton color="danger" @click="() => $router.push('/course/' + $route.params.id)">
                  Regresar
              </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <strong v-if="lessons[active_index]">{{ lessons[active_index].title }}</strong>
          </CCardHeader>
          <CCardBody>
            <Lesson v-if="lessons[active_index]" v-bind:id="lessons[active_index].id" :key="active_index" />
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="back" :disabled="active_index == 0">
              Anterior
            </CButton>
            <CButton color="primary" class="float-right" @click="next" v-if="active_index != lessons.length - 1">
              Siguiente
            </CButton>
          </CCardFooter>
        </CCard>

        <CCard v-if="active_index == lessons.length - 1 && course.quiz">
          <CButton color="success" @click="() => $router.push('/course/' + $route.params.id + '/quiz/attempt')">
            Rendir evaluación
          </CButton>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import Lesson from './Lesson'
import store from '@/store'
export default {
  name: 'Lessons',
  components: {
    'Lesson': Lesson
  },
  data: function () {
    return {
        course: {},
        lessons: [],
        active_index: 0,
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
      const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.course = response.data.data;
        })

      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id + '/lessons', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.lessons = response.data.data;
        })
  },
  methods: {
    next() {
      this.active_index += 1;
    },
    back() {
      this.active_index -= 1;
    }
  }
}
</script>
